<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <router-link :to="'/snap'" class="goback">
          <img src="images/goback.png"/>
        </router-link>
      </div>
      <div class="headerC">
        <p>连续拍支付</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="myddstatu" >
    <ul>
      <li :class="options.type == 1 ? 'on' : ''" @click="search('all')">
        <a href="javascript:;">全部({{ options.noPayCount.all }})</a>
      </li>
      <li :class="options.type == 0 ? 'on' : ''" @click="search('nopay')">
        <a href="javascript:;" 
          >待支付({{ options.noPayCount.nopay }})</a
        >
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="myddcon">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="options.loading"
        :finished="options.finished"
        :offset="1200"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="myddcon1" v-for="a in options.data" :key="a.id">
          <div class="dpbox">
            <div class="dpL">
              <a href="javascript:;">
                <span>{{ a.id }}</span>
                <img src="images/mre1.png" />
              </a>
            </div>
            <div class="dpR">
              <a href="javascript:;" v-if="a.payStatus == 0">待支付</a>
              <a href="javascript:;" v-if="a.payStatus == 1">已支付</a>
            </div>
          </div>
          <div class="shopbox">
            <div class="shopboxL">
              <img :src="a.goodsImg" />
            </div>
            <div class="shopboxR">
              <div class="shopboxR_1">
                <div class="sbr1_1">{{ a.goodsTitle }}</div>
                <div class="sbr1_2">
                  <p class="p1"></p>
                  <p class="p2">￥{{ a.payAmount }}</p>
                </div>
              </div>
              <div class="shopboxR_2" v-if="a.payStatus == 0">
                <p class="p3">
                  {{ options.set.bidPayTime }}分钟后未支付抢购将清除；
                </p>
                <p class="p4"></p>
              </div>
            </div>
          </div>
          <div class="dpbtn">
            <div class="dpbtn1">
              <img src="images/dat.png" />
              <span v-if="a.payStatus == 0">
                <vab-count-down
                  :startTime="a.startTime"
                  :endTime="a.endTime"
                  :prefix="''"
                ></vab-count-down>
              </span>
              <span v-else>{{
                stringUtils.dateFormat(a.startTime, "yyyy-MM-DD HH:mm")
              }}</span>
            </div>
            <div class="dpbtn2" v-if="!options.isHandle" >
              <router-link
                :to="'/Pay/CONTINUITY/' + a.payId"
                v-if="a.payStatus == 0"
                >支付</router-link
              >
              <a
                v-if="a.isPickUp==1"
                @click="pickUp(a.payId)"
                >提货</a
              >
            </div>
            <div class="dpbtn2" v-else>
              <router-link
                :to="'/Pay/CONTINUITY/' + a.payId"
                v-if="a.payStatus == 0"
                >支付</router-link
              >
              <a
                v-if="a.isPickUp==1"
                >提货</a
              >
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";
import snapApi from "@/api/snapApi";
import { Toast } from "vant";
import { useStore } from "vuex";
import VabCountDown from "@/components/VabCountDown.vue";
export default {
  components: { VabCountDown },
  setup() {
    const options = reactive({
      data: [],
      set: {},
      pageCount: 1,
      pageNo:1,
      loading: false,
      count: 0,
      noPayCount: {
        all:0,
        nopay:0
      },
      type:1,
      finished: false,
      refreshing: false,
      total:0,
      isHandle:false,
      isPick:true,
    });
    const params = reactive({
      draw: 1,
      pageNo: 1,
      length: 10,
      status:''
    });
    const store = useStore();
    options.set = computed(() => store.state.common.snapSet);
    watch(()=>options.set,()=>{
      var now=new Date().getTime();
      var end=new Date(options.set.endTime).getTime();
      if(now>=end){
        options.isPick=false
      }else{
        options.isPick=true
      }
    })
    async function onRefresh() {
      options.finished = false;
      options.loading = true;
      options.refreshing = true;
      onLoad();
    }
    async function onLoad() {
      console.log("refreshing:" + options.refreshing);
      if (options.refreshing) {
        options.data = [];
        options.pageCount = 1;
        options.pageNo=1;
        options.refreshing = false;
      }else{
          if (options.pageNo < options.pageCount) {
          options.pageNo = options.pageNo + 1;
        }
      }

      if (
        options.data.length > 0 &&
        options.count > 0 &&
        options.data.length >= options.count
      ) {
        options.finished = true;
        return;
      }
      params.pageNo = (options.pageNo - 1) ;
      
      await snapApi.getMySnapPay(params).then((res) => {
        if (res.status == 200) {
          options.count = res.recordsTotal;
          options.total = res.recordsTotal;
          let count = Math.ceil(res.recordsTotal / params.length);
          options.pageCount = count;
          res.data.forEach((item) => {
            if (options.data.length < res.recordsTotal) {
              options.data.push(item);
            }
          });
          options.loading = false;
          if (options.data.length >= options.total) {
            options.finished = true;
          }
        }
      });
      console.log("pageNo:" + options.pageNo+"pageCount:" + options.pageCount);
    }
    const getNoPayCount = () => {
      snapApi.getPayCount().then((res) => {
        if (res.status == 200) {
          options.noPayCount = res.result;
        }
      });
    };
    const pickUp=(id)=>{
      console.log('id:'+id);
      options.isHandle=true;
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '处理中......',
      });
      snapApi.pickUp({id:id}).then((res) => {
        toast.clear();
        options.isHandle=false;
        if (res.status == 200) {
          store.commit('setNoticeMessage', { isNoticeShow: true, close: false, title: '处理消息', type: 'START', message: "已提交,正在处理中......." })
        }else{
          Toast.fail(res.message);
        }
      });
    }
    const search=(type)=>{
      if(type=='nopay'){
        params.status=0;
        options.type=0;
      }else{
        params.status='';
        options.type=1;
      }
      onRefresh();
    }
    getNoPayCount();
    return {
      options,
      params,
      onLoad,
      onRefresh,
      search,
      pickUp
    };
  },
};
</script>
<style>
.dpbtn1 {
  width: 70% !important;
}
</style>