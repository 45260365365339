<template>
  <div class="hdbox">
    <div class="hdbox0 snap-head">
      <count-down
        @start_callback="downScb(1)"
        @end_callback="downEcb(1)"
        :currentTime="options.downOptions.currentTime"
        :startTime="options.downOptions.startTime"
        :endTime="options.downOptions.endTime"
        :tipText="'距离开始'"
        :tipTextEnd="'距离结束'"
        :endText="'已结束'"
        :dayTxt="'天'"
        :hourTxt="'时'"
        :minutesTxt="'分'"
        :secondsTxt="'秒'"
      ></count-down>
    </div>
  </div>
  <div class="hbox"></div>
  <a name="m1">
    <div class="xqbox1">
     <van-swipe :autoplay="3000" lazy-render style="height: 50vh">
        <van-swipe-item v-for="(image,index) in options.imgs" :key="image">
          <img
            :src="image.pictureMiddle"
            style="width: 100%; object-fit: cover"
            @click="openAPreview(index)"
          />
          <span
            style="
              position: fixed;
              z-index: 100000;
              right: -2rem;
              top: 16rem;
              width: 6rem;
            "
            v-if="image.isVideo == 1"
            @click="payVideo(image.pictureBig)"
            ><van-icon size="3rem" color="#1989fa" name="play-circle-o"
          /></span>
        </van-swipe-item>
      </van-swipe>
      <p class="tit">
        {{options.item.goodsTitle}}
      </p>
      <div class="qita">
        <p class="p1">今日价：￥{{stringUtils.fixedFormat(options.item.payAmount)}}</p>
      </div>
    </div>
  </a>
  <div class="clear"></div>
  <div class="kbox"></div>

  <div class="xqtab" v-show="options.item.goodsSynopsis != '' && options.item.goodsSynopsis != null">
    <div class="Menubox">
      <ul>
        <li class="hover">拍品介绍</li>
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_1">
        <div class="xqsub"  style="min-height: 5rem; text-indent: 20px; margin-top: 0.5rem;padding: 0 .1rem 0 .1rem;background: #fff;">
          {{options.item.goodsSynopsis}}
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="xqtab" v-if="options.goodsInfos.length>0">
    <div class="Menubox">
      <ul>
        <li class="hover">拍品参数</li>
        <!-- <li class=""  id="two3">热卖推荐</li> -->
      </ul>
    </div>
    <div class="Contentbox">
      <div id="con_two_2" style="margin-bottom: 2.6rem">
        <div class="canshu" v-for="info in options.goodsInfos" :key="info.id">
          <div class="canshu_1">
            <p class="pl" style="text-align: right;overflow: hidden;">{{ info.key }}</p>
            <p class="pr" style="padding-left: 0.5rem;overflow: hidden;">{{ info.value }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <div class="xqbotbox" >
    <div class="xqbotbox0">
      <div class="xqbotboxL">
        <ul >
           <li>
            <router-link to="/">
              <van-icon name="wap-home-o" size="1.8rem" />
              <p>首页</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/snapPayList'">
              <img src="images/icon1.png" />
              <p>支付</p>
            </router-link>
          </li>
           <li>
            <router-link :to="'/snap'">
              <img src="images/icon1.png" />
              <p>连续拍</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="xqbotboxR" v-if="options.isTodayBid&&options.item.isBid==0">
        <a class="a2 sizetype" @click="bid">抢拍</a>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="options.isVideoShow"
    position="top"
    :style="{ width: '100%', height: '100vh', opacity: 0.9 }"
  >
    <div style="">
      <span
        style="
          z-index: 10000;
          position: fixed;
          top: 0;
          right: 0;
          background-color: #fff;
          opacity: 0.8;
        "
        ><img @click="options.isVideoShow = false" src="images/close.png"
      /></span>

      <video
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        "
        autoplay
        controls
      >
        <source :src="options.videoUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </van-popup>
  <to-top></to-top>
     <van-image-preview
    v-model:show="options.isImgPreviewShow"
    :startPosition="options.aimgIndex"
    :images="options.viewImg"
    @change="onAChange"
  >
    <template v-slot:index>第{{ options.aimgIndex + 1 }}幅</template>
  </van-image-preview>
</template>

<script>
import { reactive, computed, watch } from "vue";
import snapApi from "@/api/snapApi";
import goodsApi from "@/api/goodsApi";
import goodsInfoApi from "@/api/goodsInfoApi"
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import CountDown from "@/components/CountDown.vue";
import ToTop from "@/components/ToTop.vue";
import { Toast } from "vant";

export default {
  components: {
    CountDown,
    ToTop
  },
  setup() {
    const route = useRoute();
    const options = reactive({
      item: {
        id: route.params.id,
        goodsTitle:'',
        payAmount:0,
        goodsId:'',
        isBid:0
      },
      imgs: [
        { url: "images/xq3.png", title: "" },
        { url: "images/xq3.png", title: "" },
      ],
      viewImg:[],
      aimgIndex:0,
      set: {},
      downOptions: {
        currentTime: new Date(),
        startTime: new Date(),
        endTime: new Date(),
      },
      goodsInfos:[],
      videoUrl:'',
      isTodayBid: false,
      isVideoShow:false,
      isImgPreviewShow:false,
      visibilitychange:false
    });
    const store = useStore();
    options.set = computed(() => store.state.common.snapSet);
    options.downOptions = computed(() => store.state.common.snapDown);
    options.visibilitychange = computed(() => store.state.common.visibilitychange);
    watch(()=>options.visibilitychange,()=>{
      if(options.visibilitychange){
        store.commit('setSnapRefresh', false)
      }
    })
    const get = () => {
      snapApi.get({ id: route.params.id }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          console.log(options.item);
          rotation(options.item.goodsId);
          getInfo();
        }
      });
    };
    const onAChange = (newIndex) => {
      options.aimgIndex = newIndex;
    };
    const openAPreview = (index) => {
      options.aimgIndex = index;
      options.isImgPreviewShow = true;
    };
    const bid = () => {
      snapApi.bid({ id: route.params.id }).then((res) => {
        if (res.status == 200) {
          sendNotice();
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const rotation=(id)=>{
      options.viewImg=[];
      goodsApi.getPicture({goodsId:id}).then(res=>{
          if(res.status==200){
            options.imgs=res.result;
            options.imgs.forEach((item)=>{
              options.viewImg.push(item.pictureMiddle);
            })
          }
      });
    }
     const payVideo = (url) => {
      options.videoUrl = url;
      options.isVideoShow = true;
    }
    const getInfo=()=>{
      goodsInfoApi.getInfoList({ productId: options.item.goodsId }).then(res => {
                if (res.status == 200) {
                    options.goodsInfos = res.result;
                }
            })
    }
     function downScb(){
        options.isTodayBid=true;
    }
    function downEcb(){
        options.isTodayBid=false;
    }
    const sendNotice = () => {
      store.commit("setNoticeMessage", {
        isNoticeShow: true,
        close: false,
        title: "处理消息",
        type: "START",
        message: "已提交,正在处理中.......",
      });
    };
    get();
    return {
      options,
      bid,
      downScb,
      downEcb,
      payVideo,
      onAChange,
      openAPreview
    };
  },
};
</script>

<style>
.snap-head {
  width: 100%;
  background: -moz-linear-gradient(top, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #000000),
    color-stop(100%, #ffffff)
  );
  background: -webkit-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  border: 0.02rem solid #ff5967;
  border-radius: 0 0 0.2rem 0.2rem;
  height: 2.8rem;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  z-index: 2000;
}
.snap-head div {
  margin-top: 0.6rem;
  margin-left: 5rem;
  margin-right: 5rem;
}
.snap-head div p {
  font-weight: bold;
  text-align: center;
  color: #9dff00 !important;
}
.snap-head div i {
  color: #ffffff;
}
</style>