<template>
  <div class="snap-head">
    <count-down
      @start_callback="downScb(1)"
      @end_callback="downEcb(1)"
      :currentTime="options.downOptions.currentTime"
      :startTime="options.downOptions.startTime"
      :endTime="options.downOptions.endTime"
      :tipText="'距离开始'"
      :tipTextEnd="'距离结束'"
      :endText="'已结束'"
      :dayTxt="'天'"
      :hourTxt="'时'"
      :minutesTxt="'分'"
      :secondsTxt="'秒'"
    ></count-down>
  </div>
  <div class="clear"></div>
  <div class="shopType" style="top: 2.8rem !important">
    <ul>
      <li
        style="width: 6rem"
        @click="options.tpye = 'DEFAULT'"
        :class="options.tpye == 'DEFAULT' ? 'on' : ''"
      >
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value1"
            @change="searchPickUp"
            :options="option1"
          />
        </van-dropdown-menu>
      </li>
      <li
        style="width: 6rem"
        @click="options.tpye = 'ORDER'"
        :class="options.tpye == 'ORDER' ? 'on' : ''"
      >
        <van-dropdown-menu>
          <van-dropdown-item
            v-model="value2"
            @change="setOrder"
            :options="option2"
          />
        </van-dropdown-menu>
      </li>
      <li
        style="width: 6rem"
        @click="searchBid"
        :class="options.tpye == 'BID' ? 'on' : ''"
      >
        <a href="javascript:;">可拍卖</a>
      </li>
      <li style="float: right">
        <a href="javascript:;" @click="options.screenShow = true" class="a_sx"
          >筛选</a
        >
      </li>
    </ul>
  </div>
  <div class="clear"></div>
  <div class="hbox1"></div>
  <to-top></to-top>
  <div class="kbox"></div>
  <div class="likebox">
    <van-pull-refresh v-model="options.refreshing" @refresh="onRefresh">
      <van-list
          v-model:loading="options.loading"
          :finished="options.finished"
          :offset="200"
          finished-text="没有更多了"
          @load="onLoad"
        >
      <ul class="van-clearfix">
       
          <li v-for="s in options.data" :key="s.id">
            <a href="javascript:;">
              <router-link :to="'/snapInfo/' + s.id">
                <van-image
                  width="10rem"
                  height="10rem"
                  class="proimg"
                  style="object-fit: cover"
                  :src="s.img"
                />
              </router-link>
              <p class="tit">{{ s.title }}</p>
              <p class="price" v-if="!options.isHandle">
                ￥{{ s.amount }}
                <b
                  style="margin-left: 1.5rem; color: #7bb008"
                  v-if="
                    s.status == 0 &&
                    (s.isEndAmount == 1 || s.isStartAmount == 1)
                  "
                  >可提货</b
                >
                <a
                  href="javascript:;"
                  v-if="options.isTodayBid && s.isBid == 0 && s.status == 0"
                  title="抢拍"
                  @click="bid(s)"
                  ><i class="fa fa-legal"></i>抢拍</a
                >
              </p>
              <p class="price" v-else>
                ￥{{ s.amount }}  {{ s.isBid }}
                <b
                  style="margin-left: 1.5rem; color: #7bb008"
                  v-if="
                    s.status == 0 &&
                    (s.isEndAmount == 1 || s.isStartAmount == 1)
                  "
                  >可提货</b
                >
               
                <a
                  href="javascript:;"
                  v-if="options.isTodayBid && s.isBid == 0 && s.status == 0"
                  title="抢拍"
                  ><i class="fa fa-legal"></i>抢拍</a
                >
              </p>
            </a>
          </li>
          <li style="margin-bottom: 4rem; width: 100%"></li>
      </ul>
    </van-list>
    </van-pull-refresh>
  </div>
  <to-top></to-top>
  <div class="hbox1"></div>
  <van-popup
    v-model:show="options.screenShow"
    position="right"
    :style="{ height: '100vh', width: '70%' }"
  >
    <div class="sxbox">
      <div class="sxbox0">
        <div class="f_mask"></div>
        <div id="sxtj">
          <div class="sx_1">筛选</div>
          <div
            style="
              margin-top: 1.5rem;
              right: 0px;
              height: 85vh;
              position: relative;
              overflow-y: scroll;
              overflow-x: hidden;
              width: 100%;
              background-color: #fff;
            "
          >
            <div class="sx_2">
              <p class="tit">是否抢拍</p>
              <a
                href="javascript:;"
                @click="setIsBid"
                :class="params.isBid == '1' ? 'onCurr' : ''"
                >抢拍</a
              >
            </div>
            <div class="sx_2">
              <p class="tit">成交价格</p>
              <div class="pric">
                <input
                  name=""
                  type="number"
                  v-model="params.startAmount"
                /><label>-</label
                ><input name="" type="number" v-model="params.endAmount" />
              </div>
            </div>
            <div class="sx_2">
              <p class="tit">类别</p>
              <div class="tys">
                <van-dropdown-menu>
                  <van-dropdown-item
                    v-model="options.type"
                    :options="options.types"
                    :lazy-render="false"
                  />
                </van-dropdown-menu>
              </div>
              <a
                href="javascript:;"
                :class="onType(t.id) ? 'onCurr' : ''"
                @click="setType(t)"
                v-for="t in options.child"
                :key="t.id"
                >{{ t.name }}</a
              >
            </div>

            <div
              class="sx_2"
              style="width: 100%"
              v-for="i in options.info"
              :key="i.id"
            >
              <div v-if="i.isAdd == 0">
                <p class="tit">{{ i.key }}</p>
                <a
                  href="javascript:;"
                  @click="setInfo(i.key, v)"
                  :class="onInfo(i.key, v) ? 'onCurr' : ''"
                  v-for="v in i.vList"
                  :key="v"
                  >{{ v }}</a
                >
              </div>
            </div>
          </div>
          <div class="sx_3">
            <a href="javascript:;" @click="screenSearch">确定</a>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <div class="xqbotbox">
    <div class="xqbotbox0">
      <div class="xqbotboxL">
        <ul>
          <li>
            <router-link to="/">
              <van-icon name="wap-home-o" size="1.8rem" />
              <p>首页</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/snapPayList'">
              <img src="images/icon1.png" />
              <p>支付</p>
            </router-link>
          </li>
          <li>
            <router-link :to="'/snap'">
              <img src="images/icon1.png" />
              <p>连续拍</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="xqbotboxR"></div>
    </div>
  </div>
</template>

<script>
import snapModel from "@/model/snap/snapModel";
import CountDown from "@/components/CountDown.vue";
import ToTop from "@/components/ToTop.vue";
export default {
  components: {
    CountDown,
    ToTop,
  },
  setup() {
    let {
      options,
      screenSearch,
      params,
      downScb,
      downEcb,
      bid,
      setOrder,
      setType,
      onType,
      setIsBid,
      onRefresh,
      onLoad,
      value1,
      option1,
      value2,
      option2,
      setInfo,
      onInfo,
      searchPickUp,
      searchBid,
    } = snapModel();
    return {
      options,
      screenSearch,
      params,
      downScb,
      downEcb,
      bid,
      setOrder,
      setType,
      onType,
      setIsBid,
      onRefresh,
      onLoad,
      value1,
      option1,
      value2,
      option2,
      setInfo,
      onInfo,
      searchPickUp,
      searchBid,
    };
  },
};
</script>

<style>
.snap-head {
  width: 100%;
  background: -moz-linear-gradient(top, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #000000),
    color-stop(100%, #ffffff)
  );
  background: -webkit-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #000000 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ff6666 0%, #ff5967 50%, #ff4165 100%);
  border: 0.02rem solid #ff5967;
  border-radius: 0 0 0.2rem 0.2rem;
  height: 2.8rem;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.8;
  position: fixed;
  top: 0px;
  z-index: 2000;
}
.snap-head div {
  margin-top: 0.6rem;
  margin-left: 5rem;
  margin-right: 5rem;
}
.snap-head div p {
  font-weight: bold;
  text-align: center;
  color: #9dff00 !important;
}
.snap-head div i {
  color: #ffffff;
}
.onCurr {
  color: #fff !important;
  background: #ff2150 !important;
}
#sxtj {
  position: relative;
  right: 0;
  width: 100%;
  height: 100vh;
}
.price a {
  float: right;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  color: #ff2150;
  background: #fff;
  font-size: 0.5rem;
  margin-top: -0.3rem;
}
.tys {
  margin-bottom: 0.5rem;
}
.tys .van-dropdown-menu {
  width: 100%;
  height: 1.2rem !important;
  background: #fff !important;
}
.tys .van-dropdown-menu__bar {
  height: 1.2rem !important;
}
.tys .van-dropdown-menu__title {
  font-size: 0.75rem !important;
}
.van-list__finished-text {
  clear: both;
}
.van-dropdown-menu__bar {
  box-shadow: none !important;
  height: 2.4rem !important;
}
</style>