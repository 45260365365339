import { reactive, computed, watch, ref } from "vue";
import configUtils from "@/config/configUtils";
import snapApi from "@/api/snapApi";
import goodsInfoApi from "@/api/goodsInfoApi";
import { useStore } from "vuex";
import { Toast } from 'vant';


const snapModel = () => {
  const options = reactive({
    data: [],
    set: {},
    pageCount: 1,
    pageNo:0,
    loading: false,
    downOptions: {
      currentTime: new Date(),
      startTime: new Date(),
      endTime: new Date(),
    },
    tpye:'DEFAULT',
    types: [],
    child: [],
    setType: [],
    type: 14,
    total: 0,
    info: [],
    keys: [],
    isTodayBid: false,
    screenShow: false,
    isRefresh: false,
    refreshing: false,
    finished: false,
    visibilitychange:false,
    isHandle:false
  });
  const params = reactive({
    draw: 1,
    pageNo: 0,
    length: 10,
    startAmount: '',
    endAmount: '',
    goodType: '',
    order: '',
    isBid: '',
    typeId: ''
  });

  const value1 = ref("all");
  const option1 = [
    { text: '全部拍品', value: 'all' },
    { text: '限价拍品', value: 'fixed' },
  ];
  const value2 = ref("a");
  const option2 = [
    { text: "默认排序", value: "a" },
    { text: "价格升序", value: "p_a" },
    { text: "价格降序", value: "p_d" },
  ];
  const store = useStore();
  options.set = computed(() => store.state.common.snapSet);
  options.types = computed(() => store.state.common.goodTypes);
  options.downOptions = computed(() => store.state.common.snapDown);
  options.isRefresh = computed(() => store.state.common.isSnapRefresh);
  options.visibilitychange = computed(() => store.state.common.visibilitychange);
  watch(()=>options.visibilitychange,()=>{
    if(options.visibilitychange){
      store.commit('setSnapRefresh', false)
    }
  })
  const searchBid=()=>{
    params.isBid=1;
    options.tpye='BID';
    onRefresh();
  }
  const setInfo = (key, val) => {
    var iclear = false;
    for (var i = 0; i < options.keys.length; i++) {
      var k = options.keys[i];
      if (k.key == key) {
        if (k.value == val) {
          iclear = true;
        }
        options.keys.splice(i, 1);
      }
    }
    if (!iclear) {
      options.keys.push({ key: key, value: val });
    }
    console.log("keys" + JSON.stringify(options.keys));
  }
  const getInfoSearch = () => {
    goodsInfoApi
      .getInfoSearch({ productTypeId: params.typeId })
      .then((res) => {
        if (res.status == 200) {
          options.info = res.result;
        }
      });
  };
  const onInfo = (key, value) => {
    var isAdd = false;
    options.keys.forEach((k) => {
      if (k.key == key && k.value == value) {
        isAdd = true;
      }
    });
    return isAdd;
  }
  watch(() => options.type, () => {
    getChildTypes();
  })
  watch(() => options.types, () => {
    getChildTypes();
  })
  watch(() => options.isRefresh, () => {
    console.log(options.isRefresh);
    if (options.isRefresh) {
      onRefresh();
    }
  })
  const searchPickUp=()=>{
    console.log(value1);
    if (value1.value == 'all') {
      params.isBid = '';
      params.startAmount = '';
      params.goodType = '';
      params.endAmount = '';
      params.order = 'a';
      params.typeId= '';
      params.pickUpStart='';
      params.pickEndStart='';
    } else {
      params.isBid = 1;
      params.startAmount = '';
      params.goodType = '';
      params.endAmount = '';
      params.order = 'a';
      params.typeId= '';
      params.pickUpStart='';
      params.pickEndStart='1';
    }
    onRefresh();
  }
  function getChildTypes() {
    options.info=[];
    options.types.forEach((item) => {
      if (item.id == options.type) {
        options.child = item.children;
      }
    })
  }
  function setIsBid() {
    params.isBid = params.isBid == '' ? '1' : '';
  }
  console.log(options.downOptions);

  async function onRefresh() {
    options.finished = false;
    options.loading = true;
    options.refreshing = true;
    onLoad();
  }
  async function onLoad() {
    console.log("refreshing:" + options.refreshing);
    if (options.refreshing) {
      options.data = [];
      options.pageCount = 1;
      options.pageNo=1;
      options.refreshing = false;
    }else{
      if(options.pageNo<options.pageCount){
        options.pageNo=options.pageNo+1;
      }
    }
    console.log("pageNo:" + options.pageNo+"pageCount:" + options.pageCount);
    if (options.data.length > 0 && options.total > 0 && options.data.length >= options.total) {
      options.finished = true;
    }
    params.pageNo =options.pageNo - 1;
  params.pageNo = params.pageNo < 0 ? 0 : params.pageNo;
    if (options.keys.length > 0) {
      params.info = JSON.stringify(options.keys);
    } else {
      params.info = "";
    }
    console.log(JSON.stringify(params));
    await snapApi.search(params).then((res) => {
      if (res.status == 200) {
        options.total = res.recordsTotal;
        let count = Math.ceil(res.recordsTotal / configUtils.page.size);
        options.pageCount = count;
        res.data.forEach((item) => {
          if (options.data.length < res.recordsTotal) {
            options.data.push(item);
          }
        });
      }
      options.loading = false;
      if (options.data.length >= options.total) {
        options.finished = true;
      }
      store.commit('setSnapRefresh', false)
    });
  }
  const setOrder = () => {
    params.order=value2
    onRefresh();
  }
  const setType = (item) => {
    console.log(item.id);
    if (params.typeId == item.id) {
      params.typeId = '';
      params.goodType = '';
      options.info=[];
    } else {
      params.typeId = item.id;
      getInfoSearch();
      params.goodType = getTypeIds(item);
    }
  }
  const onType = (value) => {
    var isAdd = false;
    if (params.typeId == value) {
      isAdd = true;
    }
    return isAdd;
  }
  const getTypeIds = (item) => {
    var listIds = [];
    listIds.push(item.id);
    if (item.children != null) {
      for (var el in item.children) {
        listIds.push(item.children[el].id);
      }
    }
    return listIds.join();
  }
  const screenSearch = () => {
    options.screenShow = false;
    onRefresh();
  }
  const bid = (item) => {
    options.isHandle=true;
    const toast = Toast.loading({
      duration: 0,
      forbidClick: true,
      message: '处理中......',
    });
    snapApi.bid({ id: item.id }).then(res => {
      options.isHandle=false;
      toast.clear();
      if (res.status == 200) {
        sendNotice();
      } else {
        Toast.fail(res.message);
      }
    });
  }
  function sendNotice() {
    store.commit('setNoticeMessage', { isNoticeShow: true, close: false, title: '处理消息', type: 'START', message: "已提交,正在处理中......." })
  }
  function downScb() {
    options.isTodayBid = true;
  }
  function downEcb() {
    options.isTodayBid = false;
  }
  getChildTypes();
  return {
    options,
    screenSearch,
    params,
    downScb,
    downEcb,
    bid,
    setOrder,
    setType,
    onType,
    setIsBid,
    onRefresh,
    onLoad,
    value1,
    option1,
    value2,
    option2,
    setInfo,
    onInfo,
    getTypeIds,
    searchPickUp,
    searchBid
  };
}
export default snapModel
